import React, { useCallback, useEffect, useMemo, useState } from "react";
import { navigate, graphql } from "gatsby";
// import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";

import BoxFilter from "../components/box-filter";
import BoxSpeaker from "../components/box-speaker";
import EngageBanner from "../components/box-engage-banner";
import BannerTickets from "../components/banner-tickets";
import BoxIniziativa from "../components/box-iniziativa";
import BoxSponsor from "../components/box-sponsor";
import BoxHelp from "../components/box-help";
import BoxNewsletter from "../components/box-newsletter";

const ALL_CATEGORY = "all";

const Speakers = ({ data: { speakers }, location }) => {
  const [page, setPage] = useState(1);
  const [currentCategory, setCurrentCategory] = useState(ALL_CATEGORY);

  const setQueryCategory = useCallback(
    (category) => {
      const params = new URLSearchParams(location.search);
      if (category === ALL_CATEGORY) {
        params.delete("category");
      } else {
        params.set("category", category);
      }
      const qs = params.toString();
      const to = (qs ? `?${qs}` : "") + location.hash;
      navigate(to);
      setCurrentCategory(category);
    },
    [location]
  );

  useEffect(() => {
    setPage(1);
  }, [currentCategory]);

  const allSpeakers = speakers.speakers;

  const filters = useMemo(() => {
    const allFilters = allSpeakers.reduce((res, speaker) => {
      res.add(speaker.program);
      return res;
    }, new Set());
    return [ALL_CATEGORY].concat([...allFilters].sort((a, b) => (a < b ? -1 : 1)));
  }, [allSpeakers]);

  useEffect(() => {
    const queryCategory = new URLSearchParams(location.search).get("category");
    if (queryCategory !== ALL_CATEGORY && filters.includes(queryCategory)) {
      setCurrentCategory(queryCategory);
    } else if (!queryCategory) {
      setCurrentCategory(ALL_CATEGORY);
    }
  }, [filters, location.search]);

  const filteredSpeakers = useMemo(
    () =>
      currentCategory === ALL_CATEGORY
        ? allSpeakers
        : allSpeakers.filter((speaker) => speaker.program === currentCategory),
    [currentCategory, allSpeakers]
  );
  const paginatedSpeakers = useMemo(
    () => filteredSpeakers.slice(0, page * 12),
    [page, filteredSpeakers]
  );
  const showMore = useCallback(() => setPage((current) => current + 1), []);

  return (
    <Layout>
      <Seo
        title={`Speakers`}
        description={`Confrontarsi e formarsi insieme ai migliori esperti italiani ed esteri.`}
      />

      <div className="box-intro">
        <div className="animation-intro-wrap">
          <video
            width="1440px"
            height="720px"
            autoPlay
            muted
            loop
            playsInline
            className="animation-intro"
          >
            <source src="/assets/speaker.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="box-intro__text">
          <div className="container-md">
            <div className="row">
              <div className="col-12 text-center">
                <div className="box-intro__claim">
                  <p>
                    Confrontarsi e formarsi insieme
                    <br />
                    ai migliori esperti italiani ed esteri.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="speakers" className="section section--grey-dark section--speaker-all">
        <div className="container-md">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
              <div className="text-center pt-5">
                Gli speaker stranieri terranno i propri interventi in inglese con traduzione
                simultanea.
              </div>
              {/*
              <div className="pt-5">
                <div className="row">
                  <div className="col-12">
                    <h2 className="text-center">Gli speaker sono in via di definizione.</h2>
                    <p className="text-center">
                      Se vuoi proporti come relatore, per condividere una storia di successo,
                      un'innovazione o uno studio su una intranet o un digital workplace,
                      contattaci.
                    </p>
                    <p>Gli argomenti delle sessioni possono riguardare:</p>
                    <ul>
                      <li>Progettazione/riprogettazione della intranet</li>
                      <li>
                        Case study sull'intranet SharePoint/o tecnologia alternativa a SharePoint
                      </li>
                      <li>Creazione e gestione di un digital workplace</li>
                      <li>Comunicazioni interne, gestione dei canali</li>
                      <li>Coinvolgimento dei dipendenti/ Esperienza digitale dei dipendenti</li>
                      <li>
                        Utilizzo di strumenti multimediali (video, podcasting, blog, wiki, chatbot,
                        intelligenza artificiale, ecc...)
                      </li>
                    </ul>
                    <p>
                      Le proposte vengono esaminate dallo staff di IID, in base ai seguenti criteri:
                    </p>
                    <ul>
                      <li>
                        l'argomento o il progetto presenta una bella sfida, con soluzioni proposte
                        (o in fase di superamento) e mostra ottimi esempi;
                      </li>
                      <li>
                        l'argomento è attuale per il settore, è all'avanguardia o presenta nuove
                        idee, presentate in modo creativo e coinvolgente;
                      </li>
                      <li>gli obiettivi di apprendimento per i partecipanti sono ben definiti.</li>
                    </ul>
                    <p>
                      I relatori non devono necessariamente appartenere a grandi aziende o società
                      di consulenza, ma possono anche rappresentare organizzazioni piccole e senza
                      scopo di lucro.
                    </p>
                  </div>
                </div>
              </div>
              */}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <BoxFilter items={filters} filter={currentCategory} setFilter={setQueryCategory} />
            </div>
          </div>
          <div className="row speakers-list">
            {paginatedSpeakers.map((item) => (
              <div key={item.id} className="col-6 col-md-4 col-lg-3">
                <BoxSpeaker data={item} showProgram={true} />
              </div>
            ))}
          </div>
          {!!paginatedSpeakers && filteredSpeakers.length > paginatedSpeakers.length && (
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-center">
                  <button type="button" onClick={showMore} className="btn-link btn-link--small">
                    <span className="btn-link__text">
                      Load <strong>more</strong>
                    </span>
                    <span className="btn-link__circle"></span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <EngageBanner />

      <BannerTickets />

      <BoxIniziativa />

      <BoxSponsor />

      <BoxHelp />

      <BoxNewsletter />
    </Layout>
  );
};

export default Speakers;

export const query = graphql`
  query {
    speakers: contentfulSpeakerList {
      speakers {
        id
        name
        surname
        role
        program
        link
        bio {
          bio
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`;
